const _defaults = {
	elem: 'abSearching',
	percent: 1,
	interval: 250,
	initialCount: 10,
	countPerInterval: 10,	// for each interval how many times the counter should be updated
	deferredPercent: 0.05	// the precentile of results that will be deferred to when the bar hit 99%
};

export class SearchProgressBox {
	constructor (config) {
		this.config = (config) ? Object.assign(_defaults, config) : _defaults;
		this.timers = {
			progressBar: null,
			resultsCount: null,
			deferredResultsCount: null
		};

		this.numberOfResults = this.config.initialCount;
		this.targetCount = this.config.initialCount;
		this.displayedResultsCount = this.config.initialCount;
		this.countingSpeed = 0;

		this.finished = false;

		this.html = document.getElementById(this.config.elem);
		this.progressBar = this.html.querySelector('.progressBar');
		this.counter = this.html.querySelector('.ui-counter');
		this.bar = null;
		this.barTxt = null;

		this.render();
	}

	render () {
		this.barTxt = document.createElement('div');
		this.barTxt.classList.add('progressBar__label');
		this.barTxt.innerText = Math.round(this.config.percent) + '%';

		this.bar = this.progressBar.querySelector('.progressBar__value');
		this.bar.appendChild(this.barTxt);

		this.startTimer();
	}

	startCountingAnimation () {
		const { interval, countPerInterval } = this.config;
		const milliseconds = interval / countPerInterval;

		this.timers.resultsCount = window.setInterval(() => {
			const newCount = this.displayedResultsCount + this.countingSpeed;
			if (newCount > this.targetCount) {
				return;
			}
			this.displayedResultsCount = newCount;
			this.counter.innerHTML = this.displayedResultsCount;
		}, milliseconds);
	}

	updateCountingSpeed () {
		this.targetCount = Math.ceil(this.numberOfResults * (1 - this.config.deferredPercent));
		const delta = this.targetCount - this.displayedResultsCount;
		const numberOfCountingIntervals = (100 - this.config.percent) * this.config.countPerInterval || 1; // we want to avoid dividing by zero
		this.countingSpeed = (delta > 0) ? Math.ceil(this.targetCount / numberOfCountingIntervals) : 0;
	}

	// This will slowly increment the counter after the progress bar reached 100% for an additional
	startDeferredCountingAnimation () {
		if (this.timers.deferredResultsCount == null && this.displayedResultsCount < this.numberOfResults) {
			this.timers.deferredResultsCount = window.setInterval(() => {
				this.displayedResultsCount += Math.ceil(Math.random() * 5);
				this.counter.innerHTML = this.displayedResultsCount;
				if (this.displayedResultsCount > this.numberOfResults) {
					window.clearInterval(this.timers.deferredResultsCount);
				}
			}, 100);
		}
	}

	startProgressAnimation () {
		this.timers.progressBar = window.setInterval(() => {
			if (this.config.percent === 1) {
				this.counter.classList.add('fade-in');
			}

			if (this.config.percent == 99) {
				this.clearIntervals();
				this.startDeferredCountingAnimation();
			} else {
				++this.config.percent;
				this.setProgressBar();
			}
		}, this.config.interval);
	}

	startTimer () {
		this.startCountingAnimation();
		this.startProgressAnimation();
	}

	setProgressBar () {
		const easeOut = t => t * (2 - t);
		const percent = Math.floor(easeOut(this.config.percent / 100) * 100);
		const percentStr = `${percent}%`;

		this.bar.style.width = percentStr;
		this.barTxt.innerText = percentStr;

		return this;
	}

	// number of preview results set by searchBox
	setNumberOfFoundResults (numberOfResults) {
		this.numberOfResults = numberOfResults;
		this.updateCountingSpeed();
	}

	clearIntervals () {
		window.clearInterval(this.timers.progressBar);
		window.clearInterval(this.timers.resultsCount);
		window.clearInterval(this.timers.deferredResultsCount);
	}

	// progress bar closed by searchBox module
	close () {
		this.finished = true;
		this.clearIntervals();
	}
}
